import './articleAuthor.scss'
import support from 'shared/assets/images/support.png'
import {isMobile} from 'react-device-detect';

export const ArticleAuthor = ({ user }) => {
    const userImage = user?.image;
    const userBio = user?.bio;
    const userName = user?.name.length < 10 ? user?.name : user?.name.slice(0, 10) + '...';

    const goToSupport = () => {
        console.log(isMobile)
        if(isMobile) {
            window.open("https://qr.kakaopay.com/FLC6rDkXW1f403950", "_blank");
        }
    }

    return <div className='author__wrapper'>
        <div className='author_user_wrapper'>
            <img className='author_user_wrapper__img' src={userImage} alt='author'></img>
            <div className='author_user_wrapper__info'>
                <p className='author_user_wrapper__name'>{userName}</p>
                <textarea className='author_user_wrapper__bio' readOnly>{userBio}</textarea>
            </div>
        </div>

        <div className='author_support' onClick={goToSupport}>
            <img className="author_support__img" src={support} alt='author support'/>
            <p className='author_support__info'>커피 한 잔 사주기!</p>
        </div>
    </div>
}