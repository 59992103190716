import './articleMarkdownEditor.scss';

import { UploadImage } from 'features/image';
import MDEditor from "@uiw/react-md-editor";
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math'
import remarkBreaks from 'remark-breaks'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import rehypeInlineCodeClassName from 'rehype-inline-code-classname';
import 'katex/dist/katex.min.css'

export const ArticleMarkdownEditor = ({ imagePath = "", data, setData }) => {
    const insertToTextArea = (intsertString) => {
        const textarea = document.querySelector('textarea');
        if (!textarea) return null;

        let sentence = textarea.value;

        const front = sentence.slice(0, textarea.selectionStart);
        const back = sentence.slice(textarea.selectionStart, sentence.length);

        sentence = front + intsertString + back;

        textarea.value = sentence;
        textarea.selectionEnd = textarea.selectionEnd + intsertString.length;

        return sentence;
    };

    return <div className="md__wrapper" data-color-mode="dark">
        <MDEditor
            value={data}
            onChange={setData}
            preview="edit"
            onPaste={async (event) => {
                const tmpText = event.clipboardData?.getData('Text');
                const tmpHtml = event.clipboardData?.getData('text/html');

                if (!tmpText && !tmpHtml) {
                    const urls = await UploadImage(imagePath, event.clipboardData);

                    urls.forEach(e => {
                        if (e?.length !== 0) setData(insertToTextArea(`![](${e})`));
                    });
                }
            }}
            onDrop={async (event) => {
                const tmpText = event.dataTransfer?.getData('Text');
                const tmpHtml = event.dataTransfer?.getData('text/html');

                if (!tmpText && !tmpHtml) {
                    const urls = await UploadImage(imagePath, event.dataTransfer);

                    urls.forEach(e => {
                        if (e?.length !== 0) setData(insertToTextArea(`![](${e})`));
                    });
                }
            }}
            height="100%"
            // minHeight={50}
            visibleDragbar={false}
            previewOptions={{
                rehypePlugins: [[rehypeKatex, rehypeRaw, rehypeInlineCodeClassName]],
                remarkPlugins: [[remarkGfm, remarkMath, remarkBreaks]],
            }}
            textareaProps={{
                placeholder: '여기에 무언가를 입력해보세요.'
            }}
        />
    </div>
};