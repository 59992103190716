// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
.share__group {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.share__url_btn {
  width: 48px;
  height: 48px;
  font-weight: bolder;
  font-size: 1rem;
  color: var(--article-text-primary);
  background: var(--share-btn-bg);
  border-radius: 24px;
  border: 0px;
  cursor: pointer;
}
.share__url_btn:hover {
  background: var(--share-btn-hover);
}`, "",{"version":3,"sources":["webpack://./src/features/blog/ui/articleShare.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,eAAA;EACA,uBAAA;EACA,SAAA;AAAR;AAEI;EACI,aAAA;EACA,uBAAA;EACA,SAAA;AAAR;AAGI;EACI,WAAA;EACA,YAAA;EAEA,mBAAA;EACA,eAAA;EAEA,kCAAA;EACA,+BAAA;EAEA,mBAAA;EACA,WAAA;EAEA,eAAA;AALR;AAOQ;EACI,kCAAA;AALZ","sourcesContent":[".share {\n    &__wrapper {\n        display: flex;\n        flex-wrap: wrap;\n        justify-content: center;\n        gap: 1rem;\n    }\n    &__group {\n        display: flex;\n        justify-content: center;\n        gap: 1rem;\n    }\n\n    &__url_btn {\n        width: 48px;\n        height: 48px;\n\n        font-weight: bolder;\n        font-size: 1rem;\n\n        color: var(--article-text-primary);\n        background: var(--share-btn-bg);\n\n        border-radius: 24px;\n        border: 0px;\n        \n        cursor: pointer;\n\n        &:hover {\n            background: var(--share-btn-hover);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
