import { API } from "shared/api";

export const getBlogArticleWithAuthor = async (id) => {
    try {
        const article = await API.get(`/blog/${id}`);
        const user = await API.get(`/auth/profile/${article.data.author}`);
        
        return {...article.data, ...user.data};
    } catch (err) {
        throw err;
    }
}