// import clsx from 'clsx'
import { useRouteLoaderData, useLoaderData, useNavigate } from "react-router-dom";
import { ArticleMarkdown } from "features/blog/ui/articleMarkdown";
import { ReactComponent as PlusIcon } from 'shared/assets/icons/pencil.svg';
import './articleDetail.scss'
import { useEffect, useRef } from "react";
import { ArticleShare, ArticlePrevNext, ArticleAuthor } from "features/blog";

export const ArticleDetail = () => {
    const user = useRouteLoaderData('root');
    const { author, category, content, creation_time, title, cid, id, name, bio, image } = useLoaderData();
    const authorInfo = { 'name': name, 'bio': bio, 'image': image };
    const formattedTime = creation_time.split('T')[0].replaceAll('-', '.');

    const navigate = useNavigate();

    const blogPerms = user?.permissions?.blog;

    const commentsEl = useRef();

    const serachParams = new URLSearchParams(window.location.search);
    serachParams.set('category', category);
    const categoryLink = '?' + serachParams.toString();

    useEffect(() => {
        const scriptEl = document.createElement("script");

        scriptEl.setAttribute("crossorigin", "anonymous");
        scriptEl.async = true;

        scriptEl.src = "https://giscus.app/client.js";
        scriptEl.setAttribute("data-repo", "Kinetic27/KineWebComment");
        scriptEl.setAttribute("data-repo-id", "R_kgDOMaLHrQ");
        scriptEl.setAttribute("data-category", "Comments");
        scriptEl.setAttribute("data-category-id", "DIC_kwDOMaLHrc4ChG37");
        scriptEl.setAttribute("data-mapping", "specific");
        scriptEl.setAttribute("data-term", id);
        scriptEl.setAttribute("data-strict", "0");
        scriptEl.setAttribute("data-reactions-enabled", "1");
        scriptEl.setAttribute("data-emit-metadata", "0");
        scriptEl.setAttribute("data-input-position", "bottom");
        scriptEl.setAttribute("data-theme", "dark_protanopia");
        scriptEl.setAttribute("data-lang", "ko");

        commentsEl.current?.appendChild(scriptEl);
    }, [id]);

    return (
        <div className='article_detail'>
            <article className="layout__article">
                <div className="wrapper__content">
                    <div className='title'>
                        <a className='title__category' href={`/b/${categoryLink}`}>{category}</a>
                        <h1 className="title__title">{title}</h1>

                        <div className="title__info">
                            <p className="title__author">{author}</p>
                            <p className="title__time">{formattedTime}</p>
                        </div>
                    </div>

                    <div className="content">
                        <ArticleMarkdown data={content} />
                    </div>

                    <div className="article_detail__author_wrapper">
                        <ArticleAuthor user={authorInfo} />
                    </div>
                    <div className="article_detail__share_wrapper">
                        <p className="article_detail__share_text">공유하기</p>
                        <ArticleShare link={window.location.href} />
                    </div>

                    <div className="article_detail__comment" ref={commentsEl} />
                    <div className="giscus" />

                    <ArticlePrevNext id={id} />
                </div>

                {(blogPerms?.admin || (blogPerms?.write && user?.id === author)) &&
                    <div className="write_btn__wrapper">
                        <PlusIcon className="write_btn" onClick={() => navigate(`/b/write/${cid}`)} />
                    </div>}
            </article>
        </div>
    )
}