import clsx from 'clsx'
import { useRouteLoaderData, useLoaderData, useNavigate, Link } from "react-router-dom";
import './blog.scss'
import { Article } from "features/blog";
import { ReactComponent as PlusIcon } from 'shared/assets/icons/plus.svg';

export const Blog = () => {
    const navigate = useNavigate();
    const user = useRouteLoaderData('root');
    const blogPerms = user?.permissions?.blog;

    const { page, size, count, data } = useLoaderData() || {};

    const displayPerPage = 5;
    const totalPage = Math.ceil(count / size);
    const start = (Math.ceil(page / displayPerPage) - 1) * displayPerPage + 1;

    const inLastPagination= (totalPage - totalPage % displayPerPage) < page;

    const noPrev = page <= displayPerPage || !data;
    const noNext = inLastPagination || !data;

    const serachParams = new URLSearchParams(window.location.search);

    serachParams.set('page', start - 1);
    const prevLink = '?' + serachParams.toString();

    serachParams.set('page', start + displayPerPage);
    const nextLink = '?' + serachParams.toString();

    return (
        <div className='blog'>
            <article className="layout__article">
                <div className='intro'>
                    <h1 className='intro__title'>BLOG</h1>
                    <h5 className='intro__subtitle'>아무거나 재밌어보이면 해보는 블로그</h5>
                </div>

                <ul className="articles">
                    {
                        data && data.map((data) =>
                            <li key={data.id}><Article articleData={data} /></li>)
                    }
                </ul>

                {!data && <h1 className='no_article_text'>게시글이 하나도 없습니다.<br />새로운 게시물을 작성해보세요.</h1>}
            </article>



            <ul className="pagenation">
                <li className={clsx('page', noPrev ? 'hide' : '')}>
                    <Link to={prevLink}>{'<'}</Link>
                </li>

                {[...Array(displayPerPage)].map((_, i) => {
                    serachParams.set('page', start + i);

                    return start + i <= totalPage &&
                        <li key={i}>
                            <Link className={clsx('page', page === start + i ? 'active' : '')}
                                to={'?' + serachParams.toString()}>
                                {start + i}
                            </Link>
                        </li>
                })}

                <li className={clsx('page', noNext ? 'hide' : '')}>
                    <Link to={nextLink}>{'>'}</Link>
                </li>
            </ul>

            {blogPerms?.write &&
                <div className="write_btn__wrapper">
                    <PlusIcon className="write_btn" onClick={() => navigate('/b/write')} />
                </div>}


        </div>
    )
}